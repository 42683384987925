import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "vue-transitions-css";

Vue.use(Vuetify);
const opts = {
  rtl: localStorage.getItem("language") == "ar",
  theme: {
    themes: {
      light: {
        red:"#ef3341",
        primary: "#002596",
        lightPrimary:"#4655bd",
        secondary: "#001489",
        graybg: "#F0F5F8",
        lightbluebg: "#F3F5F6",
        darkgray: "#DCDCDC",
        black: "#222222",
        grey: "#7C7C7C",
        darkblack: "#000000",
        purple: "#2F294D",
        blue: "#001489",
        yellow: "#FAB544",
        green: "#007A3D",
        halfwhite: "#ffffff",
        lightblue: "#89BBCB",
        lightGreyText:"#A6A0A0"
      },
    },
  },
};

export default new Vuetify(opts);
