<template>
  <div class="submit-btn">
    <v-btn
      class="c-btn mx-1"
      :class="classes"
      color="secondary"
      height="35"
      width="180px"
      @click="$emit('click')"
      :type="type"
      :disabled="loading"
    >
      <span
        v-if="!loading"
        class="white--text font-300 font-20 d-block px-12"
        >{{ $t(title) }}</span
      >
      <div class="loading" v-if="loading">
        <div id="circle1"></div>
      </div>
    </v-btn>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/global/LoadingSpinner";
import { mapGetters } from "vuex";

export default {
  component: {
    LoadingSpinner,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "mt-14 ",
    },
  },
  computed: {
    ...mapGetters(["loading"]),
  },
};
</script>

<style lang="scss">
.submit-btn {
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(250, 250, 250, 0.6);
  }

  #circle1 {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30px;
    width: 30px;
    transform: translate(-50%, -50%);
    border: 4px rgba(0, 0, 0, 0.25) solid;
    border-top: 4px black solid;
    border-bottom: 4px black solid;
    border-radius: 50%;
    -webkit-animation: spin1 1s infinite linear;
    animation: spin1 1s infinite linear;
  }

  @-webkit-keyframes spin1 {
    from {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
      transform: translate(-50%, -50%) rotate(359deg);
    }
  }
  @keyframes spin1 {
    from {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg);
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      -webkit-transform: translate(-50%, -50%) rotate(359deg);
      transform: translate(-50%, -50%) rotate(359deg);
      -webkit-transform: translate(-50%, -50%) rotate(359deg);
      transform: translate(-50%, -50%) rotate(359deg);
    }
  }
}
</style>
