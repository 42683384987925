<template>
  <div>
    <!-- if empty -->
    <div class="text-center mt-8" >
      <img :src="require('@/assets/images/draw/noTrips.png')" width="200" alt="" />
      <h2 class="font-30 primary--text d-block mt-2">{{title}}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type: String,
      default: "There is No Items"
    }
  }
};
</script>
5
<style></style>
