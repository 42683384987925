<template>
  <div class="date-style">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="base-input input-style">
          <v-text-field
            class="w-100"
            outlined
            :placeholder="placeholder"
            dense
            min-height="35px"

            :rules="rules"
            :hide-details="true"
            :value="value"
            @input="inputHandler"
            full-width
            color="purple"
            v-bind="attrs"
            v-on="on"
            readonly
            append-icon="mdi-calendar"
          >
          </v-text-field>
        </div>
      </template>
      <v-date-picker
        no-title
        v-model="date"
        @input="handleSave"
        :min="
          this.$moment().subtract(0, 'days').format('YYYY-MM-DD')
        "
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
    value: {
      validator: () => true,
    },
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 10),
    menu: false,
  }),
  methods: {
    handleSave(date) {
      this.menu = false;
      this.date = date;
      this.$emit("handleDate", date);
    },
    inputHandler(event) {
      this.$emit("input", event);
      this.date = event;
    },
  },
};
</script>

<style lang="scss">
.date-style {
  .input-style {
    .v-input__slot {
      border-radius: 10px;
      fieldset {
        height: 45px !important;
        border: 1px solid #66656533 !important;
        box-shadow: 0px 2px 10px rgb(168 168 168 / 5%);
      }
      .v-input__append-inner {
        margin-top: 10px !important;
        .v-icon {
          color: $primary;
        }
      }
      .v-text-field__slot {
        height: 45px !important;
      }
    }
    .error--text {
      .v-input__slot {
        fieldset {
          height: 45px !important;
          border: 1px solid #e44358 !important;
        }
      }
    }
  }
}
</style>
