<template>
  <div class="navbar">
    <drawer class="hidden-md-and-up" />

    <div class="max-width mt-5 hidden-sm-and-down">
      <div class="d-flex align-center justify-space-between pb-3">
        <!-- logo -->
        <div class="navbar__logo">
          <router-link to="/">
            <img
              width="160px"
              :src="require('@/assets/images/global/logo.png')"
              alt=""
            />
          </router-link>
        </div>

        
        <!-- links -->
        <div
          class="
            navbar__links
            px-14
            py-3
            d-flex
            align-center
            justify-center
            w-100
          "
        >
          <router-link
            class="d-flex align-center navbar__links__link cursor-pointer mx-5"
            v-for="(link, i) in links"
            :key="i"
            :to="link.route"
            :class="link.active ? 'navbar__links__link--active' : ''"
          >
            <!-- <img class="mr-2" :src="link.icon" alt="" /> -->
            <h4 class="primary--text font-20">{{ link.title }}</h4>
          </router-link>
        </div>

        <!-- buttons logged -->
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center mx-2" v-bind="attrs" v-on="on">
              <span class="primary--text font-500 font-20">{{
                $vuetify.rtl ? "AR" : "EN"
              }}</span>
              <v-icon color="primary">mdi-menu-down</v-icon>
            </div>
          </template>
          <v-list class="white" elevation="0">
            <v-list-item
              class="logged-nav__item px-2"
              v-for="(item, index) in languages"
              :key="index"
              @click="switchLanguage(item.value)"
              :class="
                $i18n.locale == item.value ? 'logged-nav__item--active' : ''
              "
            >
              <span class="font-18">{{ item.title }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
        <div
          class="navbar__buttons d-flex align-center"
          v-if="!userLogged && $route.name != 'verify'"
        >
          <v-btn
            class="c-btn outline-white mx-2"
            elevation="0"
            @click="$router.push('/sign-up')"
            ><span class="primary--text font-15 font-500">{{
              $t("navbar.signUp")
            }}</span></v-btn
          >
          <v-btn
            class="c-btn mx-3"
            color="primary"
            elevation="0"
            @click="$router.push('/sign-in')"
            ><span class="white--text font-15 font-500">{{
              $t("navbar.signIn")
            }}</span></v-btn
          >
        </div>

        <!-- buttons not logged -->

        <logged-nav :user="profileObj" v-if="userLogged"></logged-nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoggedNav from "./components/LoggedNav";
import Drawer from "./Drawer.vue";
export default {
  components: {
    LoggedNav,
    Drawer,
  },
  data: (vm) => ({
    languages: [
      { title: "EN", active: true, value: "en" },
      { title: "AR", active: false, value: "ar" },
    ],
    activeLanguage: "EN",
    links: [
      {
        title: vm.$t("navbar.home"),
        route: "/",
        name: "Homepage",
        icon: require("@/assets/images/icons/world.png"),
        active: false,
      },
      {
        title: vm.$t("navbar.trips"),
        route: "/trips",
        name: "trips",
        icon: require("@/assets/images/icons/air.png"),
        active: false,
      },
      {
        title: vm.$t("navbar.shipments"),
        route: "/shipments",
        name: "shipments",
        icon: require("@/assets/images/icons/rectangle.png"),
        active: false,
      },
      {
        title: vm.$t("navbar.aboutUs"),
        route: "/about",
        name: "about",
        icon: require("@/assets/images/icons/question.png"),
        active: false,
      },
      {
        title: vm.$t("navbar.contact"),
        route: "/contact-us",
        name: "Contact Us",
        icon: require("@/assets/images/icons/phone.png"),
        active: false,
      },
    ],
    obj: {},
  }),
  watch: {
    $route: {
      async handler() {
        await this.$store.dispatch("getProfile");
        this.checkRoute();
      },
    },
  },
  computed: {
    ...mapGetters(["profileObj"]),
    userLogged() {
      return this.$store.getters["userLogged"];
    },
  },
  methods: {
    checkRoute() {
      this.links.forEach((link) => {
        if (link.name == this.$route.name) {
          link.active = true;
        } else {
          link.active = false;
        }
      });
    },
    switchLanguage(language) {
      this.$vuetify.rtl = language == "ar" ? true : false;
      this.$i18n.locale = language;
      localStorage.setItem("language", language);
      this.$router.go();
    },
  },
  async created() {
    this.checkRoute();
    this.$store.dispatch("setUser", localStorage.getItem("user-logged"));
    await this.$store.dispatch("getProfile");
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  background: #fff;
  top: 0px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  z-index: 100;
  border-bottom: 1px solid #66656533;
  &__links {
    border-radius: 18px;

    &__link {
      transition: 0.3s;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
      &--active {
        opacity: 1;
      }
    }
  }
}
</style>
