<template>
  <div>
    <div
      class="upload-text text-center curosr-pointer d-flex align-center justify-center"
      :class="isEmpty ? 'upload-text--empty' : ''"
    >
      <div>
<div class="upload-img text-center" v-if="hasImage">
        <img
          class="d-block mx-auto my-12"
          height="40px"
          :src="require('@/assets/images/icons/image.png')"
          alt=""
        />
      </div>
      <v-file-input
        class="upload-text__btn"
        hide-input
        truncate-length="15"
        @change="handleChange"
      ></v-file-input>
         
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasImage: {
      type: Boolean,
      default: true,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(event) {
      if (event.size > 2000000) {
        this.$store.dispatch("showSnack", {
          text: "size should be less than 2 MB!",
          color: "error",
        });
        return;
      }
      this.$emit("change", event);
    },
  },
};
</script>

<style lang="scss">
.upload-text {
  position: relative;
  border: 1px solid #C5C5C5;
  background: #fff;
  border-radius: 5px;
  &__btn {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: block;
    padding: 0px;
  }
  .v-input__prepend-outer {
    width: 100%;
  }
  .v-input__prepend-outer,
  .v-input__icon,
  button {
    width: 100%;
    height: 100%;
    &::before {
      width: 100%;
      height: 100%;
    }
  }
  .upload-img {
    border: 1px solid transparent;
    transition: 0.3s;
    border-radius: 14px;
    overflow: hidden;
  }
  &--empty {
    border: 1px solid $secondary;
  }
}
</style>
