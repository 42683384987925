import axios from "../plugins/axios";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectedIdChate:false,
    loading_countries_categories: false,
    text: "",
    color: "",
    timeout: "",
    loading: false,
    layoutPage: "",
    userLogged: false,
    role: "",
    categories: [],
    categoriesFilter: [],
    frequency: [
      { name: "Weekly", value: "Weekly" },
      { name: "Monthly", value: "Monthly" },
      { name: "Yearly", value: "Yearly" },
    ],

    frequencyAr: [
      { name: "اسبوعياً", value: "Weekly" },
      { name: "شهرياً", value: "Monthly" },
      { name: "سنوياً", value: "Yearly" },
    ],
    countriesFilter: [],
    cities: [],
    countries:[],
    citiesFilter: [],
    weightItems: [
    ],
    userID: "",
    fcmToken: "",
    isUnread: false,
    isUnreadMassege:false,
    unreadNotifications: false,
    numberNotifications:false,
    profileObj: {},
  },
  getters: {
    loading_countries_categories(state) {
      return state.loading_countries_categories;
    },
    selectedIdChate(state){
      return state.selectedIdChate;
    },
    loading(state) {
      return state.loading;
    },
    getLayoutPage(state) {
      return state.layoutPage;
    },
    userLogged(state) {
      return state.userLogged;
    },
    fcmToken(state) {
      return state.fcmToken;
    },
    role(state) {
      return state.role;
    },
    userID(state) {
      return state.userID;
    },
    categories(state) {
      return state.categories;
    },
    countries(state) {
      return state.countries;
    },
    cities(state) {
      return state.cities;
    },
    categoriesFilter(state) {
      return state.categoriesFilter;
    },
    citiesFilter(state) {
      return state.citiesFilter;
    },
    countriesFilter(state) {
      return state.countriesFilter;
    },
    frequency(state) {
      return state.frequency;
    },
    frequencyAr(state) {
      return state.frequencyAr;
    },
    weightItems(state) {
      return state.weightItems;
    },
    isUnread(state) {
      return state.isUnread;
    },
    isUnreadMassege(state){
      return state.isUnreadMassege;
    },
    unreadNotifications(state) {
      return state.unreadNotifications;
    },
    numberNotifications(state){
      return state.numberNotifications
    },
    profileObj(state) {
      return state.profileObj;
    },
  },
  mutations: {
    setIsUnreadMassege(state,payload){
      state.isUnreadMassege=payload;
    },
    selectedIdChate(state,payload){
      state.selectedIdChate=payload;
    },
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_LAYOUTPAGE(state, payload) {
      state.layoutPage = payload;
    },
    SET_USER(state, payload) {
      localStorage.setItem("token", payload.data.accessToken);
      localStorage.setItem("role", payload.data.role);
      localStorage.setItem(
        "anapog-loggedIn",
        payload.data.accessToken ? true : false
      );
      state.userLogged = payload.data.accessToken ? true : false;
      state.userID = payload.data.userID;
      state.role = payload.data.role;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${payload.data.accessToken}`,
      };
    },

    SET_FCM(state, payload) {
      state.fcmToken = payload;
    },
    SET_GUEST(state, payload) {
      localStorage.setItem("token", payload.data.accessToken);
      localStorage.setItem("role", payload.data.role);
      localStorage.setItem("anapog-loggedIn", false);
      state.userLogged = false;
      state.role = "guest";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${payload.data.accessToken}`,
      };
    },
    REMOVE_USER(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("anapog-loggedIn");
      localStorage.removeItem("role");
      localStorage.removeItem("userID");
      state.userLogged = false;
    },
    SET_ALL_CONTENT(state, payload) {
      state.categories = [...payload.categories];
      state.categoriesFilter = [...payload.categories];
      state.categoriesFilter.unshift({ name: "All", id: "All" });

      state.countries = [...payload.countries];
      state.countriesFilter = [...payload.countries];
      state.countriesFilter.unshift({ name: "All", id: "All" });

      state.cities = [...payload.cities];
      state.citiesFilter = [...payload.cities];
      state.citiesFilter.unshift({ name: "All", id: "All" });
    },
    SET_UNREADNOTIFICATIONS(state, payload) {
      state.unreadNotifications = payload;
      // state.isUnread = true;
    },
    SET_ISNREAD(state,payload){
      state.isUnread = payload;
    },
    SET_PROFILE(state, payload) {
      state.profileObj = payload;
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    setLayout({ commit }, payload) {
      commit("SET_LAYOUTPAGE", payload);
    },
    async setUser(
      { commit },
      payload = {
        data: {
          accessToken: localStorage.getItem("token")
            ? localStorage.getItem("token")
            : "",
          role: localStorage.getItem("role")
            ? localStorage.getItem("role")
            : "",
          userID: localStorage.getItem("userID")
            ? localStorage.getItem("userID")
            : "",
        },
        userLogged: false,
      }
    ) {
      if (
        (!payload.userLogged && localStorage.getItem("role") == "guest") ||
        (!payload.userLogged && !localStorage.getItem("role"))
      ) {
        const res = await axios.post("/auth/login", {
          email: "guest@gmail.com",
          password: "password",
        });
        localStorage.removeItem("userID");
        commit("SET_GUEST", res.data);
      } else {
        if (payload.data.id) {
          localStorage.setItem("userID", payload.data.id);
        }
        commit("SET_USER", payload);
      }
    },
    async removeUser({ commit }) {
      commit("REMOVE_USER");
      const res = await axios.post("/auth/login", {
        email: "guest@gmail.com",
        password: "password",
      });
      commit("SET_GUEST", res.data);
    },
    async fetchCountries({ commit, state }) {
      state.loading_countries_categories = true;
     
      let config = {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
        },
      };
      await axios.get("getAllContent", config).then((res) => {
        if (res) {
          commit("SET_ALL_CONTENT", res.data.data);
          state.loading_countries_categories = false;
        }
      });
    },
    async setMapMarker(_, payload) {
      let { data } = await axios.post("/getAddress", {
        lat: payload.latLng.lat(),
        lng: payload.latLng.lng(),
      });

      if (data.data.country) {
        return {
          country: data.data.country,
          city: data.data.city,
          fullAddress: data.data.formatted_address,
        };
      } else {
        return false;
      }
    },
    searchHandler(_, payload) {
      let newArr = [];
      let data = [...payload.items];
      data = [
        ...payload.items.filter((item) => {
          if (payload.filter.from && payload.filter.to) {
            return (
              item.fromCountry == payload.filter.from &&
              item.toCountry == payload.filter.to
            );
          } else if (payload.filter.from) {
            return item.fromCountry == payload.filter.from;
          } else if (payload.filter.to) {
            return item.toCountry == payload.filter.to;
          } else {
            return item;
          }
        }),
      ];
      newArr = [...data];
      console.log("new", newArr);
      if (payload.filter.category && payload.type == "trips") {
        newArr = [];
        data.forEach((item) => {
          item.categories.forEach((cat) => {
            if (cat.name == payload.filter.category) {
              newArr.push(item);
            }
          });
        });
      } else if (payload.filter.category && payload.type == "shipments") {
        newArr = [
          ...newArr.filter((item) => {
            return item.categories.name == payload.filter.category;
          }),
        ];
      }
      return newArr;
    },
    async bidSubmit(context, payload) {
      let formData = new FormData();
      formData.append("shipment_id", payload.shipment_id);
      if (payload.price_shipment) {
        formData.append("price_shipment", payload.price_shipment);
      }
      formData.append("trip_id", payload.trip_id);

      let res = await axios.post(payload.url, formData);
      if (res.success) {
        context.dispatch("showSnack", {
          text: "Place Bid Successfully",
          color: "success",
        });
        return true;
      } else {
        context.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    async fetchNotifications({ commit }) {
      await axios.get("/getInfoUser").then((res) => {
        if (res.data) {
          commit("SET_UNREADNOTIFICATIONS", res.data);
        }
      });
    },
    returnShipmentArray(_, payload) {
      return payload.shipments.map((item) => {
        return {
          id: item.id,
          title: item.title,
          image: item.image1 == "#" ? this.$defaultImage : item.image1,
          images: item.images,
          toCity: item.city_destination,
          date: item.date,
          categories: item.category,
          budget: item.budget,
          weight: item.weight,
          rate: +item.rate,
          owner_id: item.owner_id,
          status: item.status,
          order_id:item.order_id,
          is_requested:item.is_requested

        };
      });
    },
    returnProductsArray(_, payload) {
      return payload.products.map((item) => {
        return {
          id: item.id,
          title: item.title,
          image: item.image == "#" ? this.$defaultImage : item.image,
          toCity: item.city_destination,
          date: item.date,
          categories: item.category,
          budget: item.budget,
          weight: item.weight,
          rate: +item.rate,
          owner_id: item.owner_id,
          status: item.status,
          order_id:item.order_id,
          isProduct:true,
          is_requested:item.is_requested

        };
      });
    },
    returnOnlineProductsArray(_, payload) {
      return payload.onlineOrders.map((item) => {
        return {
          id: item.productOnlines[0].id,
          owner_id: item.productOnlines[0].owner_id,
          title:
            item.productOnlines[0].title.length > 15
              ? item.productOnlines[0].title.slice(0, 15) + "..."
              : item.productOnlines[0].title.slice(0, 30),
          image:
            item.productOnlines[0].image == "#"
              ? this.$defaultImage
              : item.productOnlines[0].image,
          images: [],
          toCountry: item.country_destination,
          toCity: item.city_destination,
          date: item.shipment_date,
          budget: item.productOnlines[0].price,
          weight: item.productOnlines[0].weight,
          desc: item.productOnlines[0].description,
          company: item.productOnlines[0].website[0].icon,
        };
      });
    },
    async submitShipment(_, payload) {
      let formData = new FormData();
      let url = "shipment";
      if (payload.isEdit) {
        formData.append("shipment_id", payload.obj.id);
        url = "updateShipment";
      }
      formData.append("title", payload.obj.title);
      formData.append("weight", payload.obj.weight);
      formData.append("unit", payload.obj.unit);
      formData.append("budget", payload.obj.budget);
      formData.append("category_id", payload.obj.category_id);
      formData.append("note", payload.obj.note);
      payload.images.forEach((img) => {
        console.log(img);
        if (!img.id) {
          formData.append("images[]", img);
        }
      });
      formData.append("shipment_date", payload.obj.shipment_date);
      if (payload.obj.latitude_origin) {
        formData.append("country_origin", payload.obj.country_origin);
        formData.append("city_origin", payload.obj.city_origin);
        formData.append("address_origin", payload.obj.address_origin);
        formData.append("latitude_origin", payload.obj.latitude_origin);
        formData.append("longitude_origin", payload.obj.longitude_origin);
      }
      if (payload.obj.country_destination) {
        formData.append("country_destination", payload.obj.country_destination);
        formData.append("city_destination", payload.obj.city_destination);
        formData.append("address_destination", payload.obj.address_destination);
        formData.append(
          "latitude_destination",
          payload.obj.latitude_destination
        );
        formData.append(
          "longitude_destination",
          payload.obj.longitude_destination
        );
      }
      let res = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res;
    },
    async getProfile({ commit }) {
      let { data } = await axios.get("/myProfile");
      if (data) {
        commit("SET_PROFILE", data.data);
      }
    },
  },
  modules: {},
});
