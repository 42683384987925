import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/style/main.scss'
import './assets/style/rtl.scss'
import './assets/style/_variables.scss'
import "./plugins/axios";

// helper
import helper from './utils/helper'

// slick carousel
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

// base
import SkeletonCard from '@/components/base/SkeletonCard'
import BaseInput from '@/components/base/Input'
import SubmitBtn from '@/components/base/SubmitBtn'
import ViewField from '@/components/base/ViewField'
import BaseSelect from '@/components/base/Select'
import BaseTextArea from '@/components/base/TextArea'
import BaseDate from '@/components/base/Date'
import BaseUploadText from '@/components/base/UploadText'
import NoItems from '@/components/base/NoItems'
import LoadingItems from '@/components/global/LoadingSpinner'

// input country phone
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

// code input
import CodeInput from "vue-verification-code-input";

// map
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapMap from 'vue2-google-maps/dist/components/map.vue'
import VueGoogleAutocomplete from "vue-google-autocomplete";

// languages
import i18n from './i18n';
// import axios from './plugins/axios'
import firebase from 'firebase/app'
import Clipboard from 'v-clipboard'

Vue.prototype.$helper = helper
Vue.prototype.$defaultImage = "http://www.gergitavan.com/wp-content/uploads/2017/07/default-placeholder-1024x1024-570x321.png"
// map
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDMJhgeXvrKTAC4JS1RTlq47zPrnakWa6k',
    libraries: "places"
  }
})

Vue.component("submit-btn", SubmitBtn);
Vue.component("skeleton-card",SkeletonCard);
Vue.component("view-field", ViewField);
Vue.component("base-input", BaseInput);
Vue.component("base-select", BaseSelect);
Vue.component("base-textarea", BaseTextArea);
Vue.component("base-date", BaseDate);
Vue.component("base-upload-text", BaseUploadText);
Vue.component("no-items", NoItems);
Vue.component("loading-items", LoadingItems);
Vue.component("slick-carousel", VueSlickCarousel);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.component('GmapMap', GmapMap)
Vue.component('CodeInput', CodeInput)
Vue.component('vue-google-autocomplete', VueGoogleAutocomplete)

// Vue.use(axios)
Vue.use(require('vue-moment'));
Vue.use(Clipboard)

Vue.config.productionTip = false

firebase.messaging().onMessage((payload) => {
  console.log("message client: ", payload);
});
Vue.mixin({
  computed: {
    isMobile() {
      return (
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.md
      );
    },
  },
});
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)  
}).$mount('#app')
