import firebase from "firebase/app";
import "firebase/firebase-messaging";
import "firebase/auth";
import store from "../store";
import Vue from "vue"
var firebaseConfig = {
  apiKey: "AIzaSyBQd249rTlTGKLspWQZzH9L4-V26eZnWHI",
  authDomain: "anapog-923eb.firebaseapp.com",
  projectId: "anapog-923eb",
  storageBucket: "anapog-923eb.appspot.com",
  messagingSenderId: "478701826669",
  appId: "1:478701826669:web:bc9856d8b8ce352e37c564",
  measurementId: "G-Y2YMTKZ0HT",
};

// if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
// } else {
//   firebase.app(); // if already initialized, use that one
// }
const messaging = firebase.messaging();


export default async function firebaseInitialize() {
  // AAAAb3TaMm0:APA91bEXcl8geHx7LRv0iSj7MbsoZiI4LmrpHxITxvIcW0lT1eTe86tMshe70rJCVbwJ1LfYXBWURxjmk94fcSePsr71Kl1W1544kbASJYGgaUykRhvlBvVHlMYyDGxVwD1n8le4R8Wk
  messaging
    .getToken({
      vapidKey:
        "BK7AmXCiSse6KtIJ7Sy9uNkIjA-Yt18h7P7TjD10zilK7Be4JRvLJA-TejRmncw5B7i97NB_2ElvfbaPCywE3Bk",
    })
    .then((currentToken) => {
      if (currentToken) {
        store.commit("SET_FCM", currentToken);
        console.log("bb vapidKey");
      }
    })
    .catch((err) => {
      console.log(err);

      console.log("not worked");
    });

  firebase.messaging().onMessage((payload) => {
    console.log("message client: ", payload);
  });

  // messaging.onMessage(function (payload) {
  //   const title = payload.notification.title;
  //   const options = {
  //     body: payload.notification.body,
  //     icon: payload.notification.icon,
  //   };
  //   new Notification(title, options);
  // });

  messaging.onMessage(function (payload) {

    // 
    console.log('baba',payload);
    function showNotification() {
      const title = payload.notification.title;
      const options = {
        body: payload.notification.body,
        icon: payload.notification.icon,
        sound:true
      };
      let notification = new Notification(title, options);
      notification.onclick = () => {
        // notification.close();
        // window.parent.focus();
      };
    }
    let permission = Notification.permission;
    
    console.log("permission " + permission);
    if (permission === "granted") {
      console.log("AABBB");
      store.dispatch("fetchNotifications");
      if(payload.data.type=="message"){
        store.commit("setIsUnreadMassege",true)
      }
      else{
        store.commit("SET_ISNREAD",true)
      }
      store.commit("selectedIdChate", !store.state.selectedIdChate)
      showNotification();
    } else if (permission === "default") {
      console.log("bb2");
      requestAndShowPermission();
    } else {
      console.log("bb3");
      alert("Use normal alert");
    }
    function requestAndShowPermission() {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          showNotification();
        }
      });
    }
  });
}
Vue.prototype.$messaging = messaging;