<template>
  <v-app
    :class="layoutPage != 'auth' ? 'app__lightbluebg' : 'white'"
    v-if="role"
  >
    <transition name="fade" mode="out-in">
      <navbar-app v-if="layoutPage != 'auth'"> </navbar-app>
    </transition>
    <snackbar></snackbar>
    <div :class="$route.name == 'auth' || $route.name=='sign-in' ? 'mt-0' : 'mt-14'"></div>
    <transition name="fade" mode="out-in" >
      <div class="content" style="background-color: #fdfdfd; min-height: 94vh !important;">
        <router-view />
      </div>
    </transition>
    <footer-app ></footer-app>
  </v-app>
</template>

<script>
import NavbarApp from "@/layout/Navbar";
import FooterApp from "@/layout/Footer";
import { mapGetters } from "vuex";
import Snackbar from "@/components/global/Snackbar";
import firebaseInitialize from "@/plugins/firebase";

export default {
  name: "App",
  components: {
    NavbarApp,
    FooterApp,
    Snackbar,
  },
  computed: {
    ...mapGetters({
      layoutPage: "getLayoutPage",
      userLogged: "userLogged",
      role: "role",
      loading: "loading",
      fcmToken: "fcmToken",
    }),
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.fullPath.indexOf("payment/success") > -1) {
          this.$router.replace("/payment-success");
        }
      },
    },
  },
  methods: {
    async getCountries() {
      await this.$store.dispatch("fetchCountries");
    },
  },
  async created() {
    firebaseInitialize();
    await this.$store.dispatch("setUser");
  },
  mounted() {
    Notification.requestPermission()
   this.getCountries();
  },
};
</script>

<style lang="scss">

.app {
  width: 100vw;
  overflow-x: hidden;
  &__lightbluebg {
    background: #fff !important;
  }
  .content {
    min-height: 30vh;
  }
}
</style>
