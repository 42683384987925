export default {
  emailRules: [
    (v) => !!v || "E-mail is required",
    (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
  ],
  emailValidRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
  sizeRules: [
    (files) =>
      !files ||
      !files.some((file) => file.size > 2_097_152) ||
      "size should be less than 2 MB!",
  ],
  UrlRules: [
    (v) => !!v || "URL is Requeired",
    (v) =>
      new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+#]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ).test(v) || "URL in not valid",
  ],
};
