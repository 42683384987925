<template>
  <div
    class="base-input input-style base-select"
    :class="isWeight ? 'base-weight' : ''"
  >
    <v-select
      :items="items"
      outlined
      :placeholder="placeholder"
      dense
      :chips="multiple ? true : false"
      :loading="loading_countries_categories || loading"
      min-height="35px"
      :hide-details="hideDetails"
      :item-value="itemValue"
      :item-text="itemText"
      :value="value"
      :rules="rules"
      :multiple="multiple"
      @input="$emit('input', $event)"
      color="purple"
      size="sm"
    >
      <template v-slot:progress>
        <div>
          <v-progress-circular
            :size="15"
            :width="2"
            class="mt-2"
            color="lightGreyText"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
    </v-select>
    <span v-if="isWeight" class="budget font-15 purple--text d-inline-block"
      >{{$t('kg')}}</span
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "name",
    },
    isWeight: {
      type: Boolean,
      default: false,
    },
    value: {
      validator: () => true,
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || ""],
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading:{
      type:Boolean,
      default:false,
    }
  },
  computed: {
    ...mapGetters(["loading_countries_categories"]),
  },
};
</script>

<style lang="scss">
.base-select {
  position: relative;
  .v-input__slot {
    border-radius: 7px;

    fieldset {
      height: 100% !important;
      border: 1px solid #66656533 !important;
      box-shadow: 0px 2px 10px rgb(168 168 168 / 5%);
    }
    .v-input__append-inner {
      margin-top: 7px !important;
      margin-bottom: 7px !important;
    }
    .v-text-field__slot {
      height: 45px !important;
    }
  }
  .error--text {
    .v-input__slot {
      fieldset {
        height: 45px !important;
        border: 1px solid #e44358 !important;
      }
    }
  }
}
.base-weight {
  .v-input__append-inner {
    display: none;
  }
}
.base-weight::-webkit-outer-spin-button,
.base-weight::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.base-weight[type="number"] {
  -moz-appearance: textfield;
}
.budget {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  padding-left: 10px;
  height: 100%;
  line-height: 44px;
  display: flex;
  &::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-55%);
    width: 1px;
    background: $purple;
    height: 70%;
  }
}
</style>
