import Vue from "vue";
import axios from "axios";
import store from "../store";
Vue.prototype.$baseURL = "https://fitoapp.de/Anapog/anapog";
// import router from "../router/index.js";

axios.defaults.baseURL = `${Vue.prototype.$baseURL}/api`;

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: "https://fitoapp.de/Anapog/anapog/api",
  headers: {
    accept: "application/json",
    "access-control-allow-origin": "*",
    "Access-Control-Allow-Headers": "*",
    // Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Accept-Language": localStorage.getItem("language"),
  },
  validateStatus: () => true,
});

axiosIns.interceptors.request.use(
  (confiq) => {
    confiq.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    store.dispatch("loading", true);
    return confiq;
  },
  (erorr) => {
    return erorr;
  }
);

axiosIns.interceptors.response.use(function (response) {
  if (response.status === 422 || response.status === 401) {
    if (response.status === 401) {
      
      // store.dispatch("showSnack", {
      //   text: "خطء",
      //   color: "error",
      // });
    } else {
      store.dispatch("showSnack", {
        text: "خطء",
        color: "error",
      });
    }
  }
  return response;
});

Vue.prototype.$axios = axiosIns;
export default axiosIns;
