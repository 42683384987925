<template>
  <div class="logged-nav" style="background-color:#fff;" >
    <div class="d-flex align-center">

      <!-- language -->
      <!-- <v-menu offset-y left>
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex align-center mx-2" v-bind="attrs" v-on="on">
            <span class="primary--text font-500 font-20">{{
              $vuetify.rtl ? "AR" : "EN"
            }}</span>
            <v-icon color="primary">mdi-menu-down</v-icon>
          </div>
        </template>
        <v-list class="white" elevation="0">
          <v-list-item
            class="logged-nav__item px-2"
            v-for="(item, index) in languages"
            :key="index"
            @click="switchLanguage(item.value)"
            :class="
              $i18n.locale == item.value ? 'logged-nav__item--active' : ''
            "
          >
            <span class="font-18">{{ item.title }}</span>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <!-- chat -->
      <v-badge
        :value="isUnreadMassege"
        color="red"
        offset-x="15"
        offset-y="8"
        dot
      >
        <img
          width="22"
          height="20"
          @click="$router.push('/profile/chat')"
          class="mx-2 cursor-pointer"
          :src="require('@/assets/images/profile/chat_header.png')"
          alt=""
        />
      </v-badge>
      <!-- notification -->
      <div class="d-flex align-center">
        <v-menu offset-y left offset-x style="width: 400px;">
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              :value="isUnread"
              color="red"
              offset-x="20"
              offset-y="10"
              dot
            >
              <img
                v-bind="attrs"
                v-on="on"
                width="21"
                height="21"
                @click="getNotifications"
                class="logged-nav__notification mx-3 cursor-pointer"
                :src="require('@/assets/images/profile/notification_header.png')"
                alt=""
              />
            </v-badge>
          </template>
          <v-list class="" elevation="0">
            <div v-if="loading">
              <v-skeleton-loader
                v-for="n in 8"
                :key="n"
                type="image"
                class="mt-1 mx-2 max-width"
                height="40px"
                width="320px"
              ></v-skeleton-loader>
            </div>
            <div
              class="px-10 py-3"
              v-else-if="filterNoftifications.length == 0 && !loading"
            >
              <span>{{ $t("noNotificationYet") }}</span>
            </div>
            <div v-else v-for="(item, index) in filterNoftifications" :key="index" style="width: 350px;">
              <div
                v-if="item.read_at == 0 ? true : false"
                style="
                  width: 8px;
                  height: 8px;
                  background-color: #001489;
                  position: absolute;
                  right: 7px;
                  border-radius: 50%;
                "
              ></div>
              <v-list-item
                class="logged-nav__item px-2 d-flex align-center"
                @click="typeHandler(item)" 
              >
                <v-list-item-avatar>
                  <v-img
                    :src="
                      item.sender_imag == '#' ? $defaultImage : item.sender_imag
                    "
                    alt=""
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <span class="font-20 black--text">{{ item.title }}</span>
                  <p class="font-15 black--text font-300 mb-0 mt-1">
                    {{ item.body.slice(0, 100) }}
                    <span v-if="item.body.length > 100">...</span>
                  </p>
                </v-list-item-content>
              </v-list-item>
            </div>
            <div
              class="text-center py-2 cursor-pointer"
              v-if="noftifications.length > 5"
              @click="$router.push({name:'Profile-Notification'})"
            >
              <span class="font-18 primary--text font-500">{{$t('seeAll')}}</span>
            </div>
          </v-list>
        </v-menu>
      </div>

      <!-- profile -->
      <div class="d-flex align-center">
        <div class="logged-nav__line"></div>
        <div
          class="logged-nav__profile grey cursor-pointer"
          @click="$router.push('/profile')"
        >
          <img
            class="object-cover"
            width="100%"
            height="100%"
            :src="
              user.image
                ? user.image == '#'
                  ? userImage
                  : user.image
                : userImage
            "
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    languages: [
      { title: "EN", active: true, value: "en" },
      { title: "AR", active: false, value: "ar" },
    ],
    activeLanguage: "EN",
    noftifications: [],
    userImage: require("@/assets/images/global/user-default.jpg"),
    loading: false,
  }),
  computed: {
    ...mapGetters(["isUnread", "isUnreadMassege"]),
    filterNoftifications() {
      return this.noftifications.slice(0, 5);
    },
  },
  methods: {
    switchLanguage(language) {
      this.$vuetify.rtl = language == "ar" ? true : false;
      this.$i18n.locale = language;
      localStorage.setItem("language", language);
      this.$router.go();
    },
    async getNotifications() {
      this.loading = true;
      let { data } = await this.$axios(`/getNotifications?page=1`);
      if (data) {
        this.noftifications = data.data.notifications;
        this.loading = false;
      }
    },
    typeHandler(item) {
      if (item.type == "message") {
        this.$router.push(`/profile/chat/${item.sender_id}`);
      }
      if (item.type == "bid shipment") {
        this.$router.push(`/myShipment/details/${item.object_id}`);
      }
      if (item.type == "bid product") {
        this.$router.push(`/myProduct/details/${item.object_id}`);
      }
      if (item.type == "completed order") {
        this.$router.push(`/profile/shipments`);
      }
    },
  },
};
</script>

<style lang="scss">

.logged-nav {

  .theme--light.v-skeleton-loader .v-skeleton-loader__bone{
    width: 100% !important;
    // height: 40px !important;
  }
  .v-skeleton-loader__actions{
    padding: 0px !important;
  }

  &__profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__lang-circle {
    width: 15px;
    min-width: 15px;
    height: 15px;
    background: white;
    border-radius: 50%;
    position: relative;
    &__selected {
      background: $secondary;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transition: 0.3s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    &--active & {
      &__selected {
        opacity: 1;
      }
    }
  }

  &__item {
    border-bottom: 1px solid rgba($lightbluebg, 0.6);
    margin: 0px 10px;
    border-radius: 5px;
    &:last-child {
      border-bottom: 0px;
    }

    span {
      transition: 0.3s;
      color: $primary;
    }
    &--active {
      background: $primary;
      span {
        color: white !important;
      }
    }
  }

  &__notification-circle {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
