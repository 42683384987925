const routes = [
  {
    path: "/",
    name: "Homepage",
    component: () => import("../views/home/Index.vue"),
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("../views/auth/sign-in/Index.vue"),
  },
  {
    path: "/sign-up",
    name: "auth",
    component: () => import("../views/auth/sign-up/Index.vue"),
  },
  {
    path: "/verify-account",
    name: "verify",
    component: () => import("../views/auth/verify-account/Index.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about/Index.vue"),
  },
  {
    path: "/verification",
    name: "verification",
    component: () => import("../views/verification/Index.vue"),
  },
  {
    path: "/trips",
    name: "trips",
    component: () => import("../views/trips/Index.vue"),
  },
  {
    path: "/trips/:id",
    name: "details-trips",
    component: () => import("../views/trips/details/Index.vue"),
  },
  {
    path: "/add-trip",
    name: "Add Trip",
    component: () => import("../views/add-trip/Index.vue"),
  },
  {
    path: "/buy-product",
    name: "Buy Product",
    component: () => import("../views/buy-for-me/Index.vue"),
    children: [
      {
        path: "online",
        name: "Online Product",
        component: () => import("../views/buy-for-me/online/Index.vue"),
      },
      {
        path: "offline",
        name: "Offline Product",
        component: () => import("../views/buy-for-me/offline/Index.vue"),
      },
    ],
  },
  {
    path: "/shipments",
    name: "shipments",
    component: () => import("../views/shipments/Index.vue"),
  },
  {
    path: "/shipment-details/:id",
    name: "shipment",
    component: () => import("../views/shipments/details/Index.vue"),
  },
  {
    path: "/buy&ship/:id",
    name: "product",
    component: () => import("../views/shipments/details/Index.vue"),
  },
  {
    path: "/trip/:id/request-shipments",
    name: "Request Shipments",
    component: () => import("../views/trips/requests/Index.vue"),
  },
  {
    path: "/add-shipment",
    name: "Add Shipment",
    component: () => import("../views/add-shipment/Index.vue"),
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: () => import("../views/contact-us/Index.vue"),
  },
  {
    path: "/product-details/:id",
    name: "Product Details",
    component: () => import("../views/product-details/Index.vue"),
  },
  {
    path: "/product-order/:id",
    name: "Product Order",
    component: () => import("../views/product-details/Index.vue"),
  },
  {
    path: "/profile/",
    name: "Profile",
    component: () => import("../views/profile/Index.vue"),
    meta: { auth: true },
    children: [
      {
        path: "",
        redirect: "user",
      },
      {
        path: "user",
        name: "Profile-User",
        component: () => import("../views/profile/pages/User.vue"),
      },
      {
        path: "shipments",
        name: "Profile-Shipments",
        component: () => import("../views/profile/pages/Shipments.vue"),
      },
      {
        path: "products",
        name: "Profile-Products",
        component: () => import("../views/profile/pages/Shipments.vue"),
      },
      {
        path: "/myShipment/details/:id",
        name: "myShipment",
        component: () =>
          import("../views/profile/pages/shipment-details/Index.vue"),
      },
      {
        path: "/myProduct/details/:id",
        name: "myProduct",
        component: () =>
          import("../views/profile/pages/shipment-details/Index.vue"),
      },
      {
        path: "trips",
        name: "Profile-Trips",
        component: () => import("../views/profile/pages/Trips.vue"),
      },
      {
        path: "/trip/details/:id",
        name: "Profile-Trip-details",
        component: () =>
          import("../views/profile/pages/trip-details/Index.vue"),
      },
      {
        path: "wallet",
        name: "Profile-Wallet",
        component: () => import("../views/profile/pages/Wallet.vue"),
      },
      {
        path: "notifications",
        name: "Profile-Notification",
        component: () => import("../views/profile/pages/Notifications.vue"),
      },
      {
        path: "chat",
        name: "Profile-Chat",
        component: () => import("../views/profile/pages/Chat.vue"),
      },
      {
        path: "chat/:id",
        name: "Profile-Chat-de",
        component: () => import("../views/profile/pages/Chat.vue"),
      },
    ],
  },
  {
    path: "/payment-success",
    name: "Success",
    component: () => import("../views/success-page/Index.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/privacy/index.vue"),
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: () => import("../views/terms-of-use/index.vue"),
  },
  {
    path: "*",
    name: "Error",
    component: () => import("../views/error-page/Index.vue"),
  },

  
  
];

export default routes;
