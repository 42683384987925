<template>
  <div class="drawer">
    <v-app-bar
      height="65"
      prominent
      class="w-100 d-flex align-center justify-space-between"
      color="primary"
      fixed
      elevation="0"
    >
      <router-link to="/" tag="span">
        <span class="d-flex align-center">
          <img
            class="logo"
            :src="require(`@/assets/images/global/logo-white.png`)"
            alt=""
          />
        </span>
      </router-link>
      <v-app-bar-nav-icon
        style="z-index: 999999"
        color="white"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      right
      temporary
      height="100vh"
      color="primary"
      class="px-3"
    >
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item class="text-right">
            <router-link to="/" tag="span" class="text-right w-100">
              <img
                class="logo"
                :src="require(`@/assets/images/global/logo-white.png`)"
                alt=""
              />
            </router-link>
          </v-list-item>
          <router-link
            class="d-flex align-center navbar__links__link cursor-pointer mb-5 justify-end"
            v-for="(link, i) in filteredLinks"
            :key="i"
            :to="link.route"
          >
            <span class="white--text font-20 font-500">{{ link.title }}</span>
            <img class="ml-3" :src="link.icon" alt="" />
          </router-link>

          <!-- if logged -->
          <div v-if="userLogged">
            <v-btn
              class="c-btn outline-white d-block mt-4"
              elevation="0"
              block
              height="45"
              @click="$router.push('/profile')"
              ><span class="primary--text font-22">Profile</span></v-btn
            >
          </div>

          <!-- if not logged -->
          <div class="text-right mt-3" v-if="!userLogged">
            <v-btn
              class="c-btn outline-white d-block"
              elevation="0"
              block
              @click="$router.push('/sign-up')"
              ><span class="primary--text font-18">Sign Up</span></v-btn
            >
            <v-btn
              class="c-btn outline-white d-block mt-4"
              elevation="0"
              block
              @click="$router.push('/sign-in')"
              ><span class="primary--text font-18">Sign In</span></v-btn
            >
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: "",
    links: [
      {
        title: "Browse",
        route: "/",
        icon: require("@/assets/images/icons/world.png"),
      },
      {
        title: "Trips",
        route: "/trips",
        icon: require("@/assets/images/icons/air.png"),
      },
      {
        title: "Shipments",
        route: "/shipments",
        icon: require("@/assets/images/icons/rectangle.png"),
      },
      {
        title: "Contact Us",
        route: "/contact-us",
        icon: require("@/assets/images/icons/phone.png"),
      },
      {
        title: "About Us",
        route: "/about",
        icon: require("@/assets/images/icons/question.png"),
      },
    ],
  }),
  computed: {
    userLogged() {
      return this.$store.getters["userLogged"];
    },
    filteredLinks() {
      // if (this.userLogged) {
      //   return [
      //     ...this.links,
      //     {
      //       title: "Chat",
      //       route: "/profile/chat",
      //       icon: require("@/assets/images/icons/chat.png"),
      //     },
      //     {
      //       title: "Notifications",
      //       route: "/profile/notifications",
      //       icon: require("@/assets/images/icons/notification.png"),
      //     },
      //   ];
      // } else {
      //   return [...this.links];
      // }
      return [...this.links]
    },
  },
  methods: {
    logoutHandler() {
      localStorage.removeItem("user-logged");
      this.$store.dispatch(
        "setUser",
        localStorage.getItem("user-logged")
      );
      this.$router.replace("/");
    },
  },
  created() {
    this.$store.dispatch(
      "setUser",
      localStorage.getItem("user-logged")
    );
  },
};
</script>

<style lang="scss">
.v-toolbar__content {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100%;
}
.drawer {
  z-index: 9999999999999999999 !important;
  position: relative;
  .logo {
    width: 110px !important;
  }
  .v-list {
    background: transparent !important;
    i {
      color: white;
    }
  }
  .v-list-group__header {
    i {
      color: white !important;
    }
  }
}
</style>
