export default {
  lang: "lang",
  navbar: {
    browse: "Browse",
    home: "Home",
    trips: "Trips",
    shipments: "Shipments",
    contact: "Contact Us",
    aboutUs: "About Us",
    about: "About",
    signUp: "Sign Up",
    signIn: "Sign In",
    privacy: "Privacy",
    termsOfUs: "Terms of use",
    subscribe: "Subscribe",
    message: "Message",
    likeUsOn: "Like us on",
    followUsOn: "Follow us on",
    links: "Links",
    order_number:"order number",
    orderName:"order name",
    footerDesc:
    "Anapog is a social platform that connects travelers with those who want to bring or buy items from places travlers are traveling from ",
  },
  goToHomepage:"Go to home page",
  cantFindPage:"We can't find that page.",
  afterYourExperience:"After Your Experience",
  seeAll:"See All",
  noTrips: "There is No Trips",
  noShipment:"There is No Shipment",
  totalInOut:"Total",
  registerMessage: "Register account, to start using Anapog services",
  submit:"Submit",
  waitingToAccept:"Waiting for the bids accepted",
  reportTitle: "Are you sure to report this shipment?",
  reportMessage: "report message",
  goToTheCity:"Find the place",
  products: "products",
  kg:"kg",
  pleaseWait:"Please Wait",
  sendRate:"Send Rate",
  deliveryShipment:"delivery shipment",
  searsh:"Searsh",
  InProgress: "In Progress",
  loremAddLink:"Add Link",
  rating: "Rating",
  orders:"Orders",
  updateProfile: "Update Profile",
  EL: " Enter link",
  name:"Name",
  selectChat:"Start Conversation",
  search:"Search",
  acceptingBids:"Accepting Bids",
  enterCode:"Enter Code",
  codeSentTraveler:"Please enter the special code you received",
  Readmore:"Read More",
  sendCode:"Send Code",
  showCode:"Show Code",
  clickToCopy:"Copy Code",

  enterYourPrice:"Please Enter your Price & your Date for this shipment",
  noBidsYet:"No Bids",
  noChatsFound:"No conversations",

  homepage: {
    shopAnywhere: "SHOP - TRAVEL - DELIVER - EARN",
    whatis: "What is",
    headTitle:
      "Anapog is a social platform that connects travelers with those who want to bring or buy items from places travlers are traveling from ",
      secondTitle: 'An easy and secure wey to send or buy and send items',
    travelers: [
      {
        title: "Select an item you can deliver and earn money while travelling",
        desc: `Select the item to be delivered to the destination you're traveling to and earn money to deliver it.`,
      },
      {
        title:
          "Inquire about the details of the item to be delivered or purchased ",
        desc: "Chat with your partner to agree on the shipments details (price, weight, date and place of delivery)",
      },
      {
        title: "Select the item to be delivered or purchased",
        desc: "Bring the selected item and delivery it to shopper / sender. ",
      },
      {
        title:
          "Hand over the item to the shopper/sender and receive your charges.",
        desc: "Coordinate with your partner the time to deliver the item and receive your charges.",
      },
    ],
    senders: [
      {
        title: "Entre item to be sent:",
        desc: `You can send any item through Anapog members (travelers)`,
      },
      {
        title: "Check out trips:",
        desc: "Your order will be available for biding to deliver your item by all traveler",
      },
      {
        title: "Select the right offer and pay fees",
        desc: "After checking all offers by travelers, select the suitalbe offer and traveler. Pay charges to Anapog, charges will be realized by Anapog to the traveler once delivery is confirmed.                ",
      },
      {
        title: " Receipt of item and payment release:",
        desc: "Item gets delivered and payment is released upon confirmation of receipt.",
      },
    ],
    services: [
      {
        title: "Secure Online Payments",
        desc: "Your money is safe, Anapog uses a secure payment system",
      },
      {
        title: "Community of Verified Shoppers and Travelers",
        desc: "Anapog provides travelers verification and rating and service reviews",
      },
      {
        title: "Guaranteed Delivery",
        desc: "No payment is made until item is delivered",
      },
    ],
    recommendProductsDesc:
      "Select any item you want, Anapog travelers will buy it and deliver it to you.",
    recommendWebsitesDesc:
      "Pick any item from our recommended websites, & Anapog travelers will buy it and deliver it for you.",
    cardDeliver:
      "Do you have items in other countries you want to deliver? Anapog travelers will deliver them for you.",
    cardBuy:
      "Select the desired item, Anapog travelers will purchase it and deliver it to you.",
  },
  toAddTrip:
    'To be able to deliver items, you need to register as a traveler on <a href="#">Anapog.com</a>',
  noShipments: "You do not have any shipments yet",
  progress: {
    pending:
      "Your order is activated, you will be receiving offers to deliver your item shortly",
    agreementCompleted: `Traveler's (traveler name) offer is accepted. Please proceed with the next step to start the delivery process`,
    payment:
      "Delivery charges have been held by Anapog, fees will be transferred to traveler upon completion of delivery",
    inProgress: "Delivery is in process",
    completed: "Delivery is completed and receipt is confirmed by sender",
  },
  shipment: {
    title: "Select the desired item(s) to deliver:",
    desc: "Once item(s) is/are selected you can contact the sender to finalize agreement",
  },
  addTripTitle:
    "Select from the trips available with Anapog travelers to send your items.",
  dialogs: {
    shipmentWait: {
      title: "Your shipment has been sent to the traveler successfully",
      desc: "Please wait for a response",
    },
    addShipment: "Your shipment has been successfully added",
    goToMyShipemnts: `Go to "my shipments"`,
    areYouSure: "Are you sure to detele this?",
    paypal: {
      step1: "Please enter your Paypal Account",
      step2: "Send To Traveler Name",
    },
    codeTitle: "Enter the code sent to you by the traveler",
    rateTitle: "After your experience with this trip.. what is your rating?",
    verifySoon: "Your account will be verified soon",
    areYouSureCancel:"Are you sure to cancel this?"

  },

  logo: "Anapog",
  cancel: "Cancel",
  ok: "OK",
  yes: "Yes",
  no: "close",
  wait: "Wait",
  edit: "Edit",
  send: "Send",
  forSenders: "For Senders",
  forTravlers: "For Travelers",
  deliverForMe: "Connect - Bring - Earn",
  buyForMe: "Buy - Bring or Deliver - Earn",
  addNewShipment: "Add new shipment",
  addShipment: "Add Shipment",
  addProduct: "Add Product",
  addYourTrip: "Add your trip",
  allTrips: "All Trips",
  recomendedProducts: "Recomended Products",
  recomendedWebsites: "Recomended Websites",
  downloadApp:
    "For the best experience, Download our app for iPhone or Android",
  serviceTitle: "We pride ourselves on quality service",
  serviceDesc:
    "It's safe, easy and a fun way to make money traveling the world.",
  goToHompage: "Go To Homepage",
  error404: "Ops...Not Found Page!!",
  buyNow: "Buy Now",
  productDetails: "Product Details",
  link: "Link",
  category: "Category",
  Wieght: "Wieght",
  price: "Price",
  description: "Description",
  country: "Country",
  distinationAddress: "Distination Address",
  deliveryDate: "Delivery Date",
  orginAddress: "Orgin Address",
  GetInTouch: "Get In Touch",
  contactDesc:
    "Fill out the form. Our team will get back to you within 24 hours",
  yourName: "Your Name",
  yourEmail: "Your Email",
  yourMessage: "Your Message",
  from: "From",
  to: "To",
  details: "Details",
  moreDetails: "More Details",
  tripDetails: "Trip Details",
  date: "Date",
  sendMessage: "Send Message To",
  requestShipment: "Request Shipment",
  ship: "Ship",
  shiped:"requested",
  youDonthaveShipments:"You Don’t have any Shipments !",
  myProfile: "My Profile",
  myTrips: "My Trips",
  myShipments: "My Shipments",
  shipments: "Shipments",
  myWallet: "My Wallet",
  signOut: "Sign-out",
  budget: "Budget",
  shipmentName: "Shipment Name",
  notes: "Notes",
  next: "Next",
  enterDetails: "Enter Details",
  enterAddress: "Enter Address",
  address: "Address",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phoneNumber: "Phone Number",
  editProfile: "EditProfile",
  changePassword: "Change Password",
  oldPassword: "Old Password",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  save: "Save",
  allStatus: "All Status",
  status: "Status",
  currentBids: "Current Bids",
  deleteShipment: "Delete Shipment",
  enterQrCode: "Enter QR code",
  deliveryStage:"Delivery Stage",
  accept: "Accept",
  pleaseEnterPhone: "Please enter your phone Number",
  receiverDigits: "You’ll receive a 4 Digital Code To verify your number",
  code: "Code",
  passport: "Passport",
  paypal: "Paypal",
  weSentCode: "We have sent the code verification to your mobile number",
  uploadPassport: "Please Upload your Passport",
  dragFiles: "Or Drag and Drop your PDF",
  upload: "Upload",
  areYouTraveler: "Are you Traveler ?",
  total: "Total",
  order: "Order",
  totalIn: "Total In",
  totalOut: "Total Out",
  in:"IN",
  out:"OUT",
  allShipments: "All Shipments",
  onlyShip: "Ship Only or Ship",
  buyShip: "Buy & Ship",
  placeBid: "Place Bid",
  trip: "Trip",
  weight: "Weight",
  ratings: "ratings",
  editTrip: "Edit Trip",
  addNewTrip: "Add new trip",
  fromCountry: "From Country",
  toCountry: "To Country",
  fromCity: "From City",
  toCity: "To City",
  frequency: "Frequency",
  originCountry: "Origin Country",
  originCity: "Origin city",
  destinationCountry: "Destination Country",
  originAddress: "Origin Address",
  destinationAddress: "Destination Address",
  destinationCity: "Destination city",
  shipmentDetails: "Shipment Details",
  inProgress: "In Progress",
  addNewProduct: "Add new product",
  allProducts: "All Products",
  categories: "Categories",
  signIn: "Sign In",
  signUp: "Sign Up",
  welcomeTo: "Welcome To",
  alreadyhaveAnAccount: "Already have An Account",
  IAgree: "I Agree",
  addTrip: "Add Trip",
  goToMyTrips: "Go To My Trips",
  goToMyShipments: "Go To My Shipments",
  goToMyProducts: "Go To My Products",
  shipmentSuccessfullyAdded: "Shipment Successfully Added",
  tripSuccessfullyAdded: "Trip Successfully Added",
  productSuccessfullyAdded: "Product Successfully Added",
  shipmentDate: "Shipment Date",
  productName: "Product Name",
  linkURL: "Link URL",
  addLink: "Add Link",
  OfflineProduct: "Offline Product",
  cantAddfourImage:"You can't add more than 4 photos",


  enterProduct: "Enter Product",
  reviewProduct: "Review Product",
  enterAddressSend: "Enter Address & Send",
  fillUp: "Fill Up",
  uploadImage: "Upload Image",
  visitLink: "Visit Link",
  note: "Note",
  website: "Website",
  url: "URL",
  waiting: "Waiting",
  noNotificationYet: "No Notification Yet",
  toAddATrip: "To Add A Trip",
  verification: "Verfication",

  pleaseEnterYourPhoneNumber: "Please Enter Your PhoneNumber",
  receiveDigital: "Receive Digital",
  countryCode: "Country Code",
  weHaveSentCode: "We have sent code to your phone please check...",
  pleaseInsertCode: "Please insert code that we sent to ",
verify: "Verfiy",
back: "Back",
city: "City",
goQuicklyToCity: "Go quickly to city",
browse: 'Browse',
noNotification: 'No notification to show',
notifications: 'Notifications',
chats: 'Chats',
updateEmail: "Update email",

  messages: {
    mustBe6: "Number must be 6 digits",
    loggedSuccessfully: "Logged successfully",
    selectDate: "Select date please",
    selectCategory: "Select category please"    
  },
  ourServices: "Our Services"
};
