export default {
  lang: "lang",
  navbar: {
    browse: "تصفح",
    home: "الصفحة الرئيسية",
    trips: "الرحلات",
    shipments: "الشحنات",
    contact: "تواصل معنا",
    aboutUs: "حول",
    about: "حول",
    signUp: "حساب جديد",
    signIn: "تسجيل دخول",
    privacy: "خصوصية",
    termsOfUs: "تعليمات الاستخدام",
    subscribe: "اشتراك",
    message: "رسالة",
    likeUsOn: "مثلنا",
    followUsOn: "تابعنا على",
    links: "الروابط",
    footerDesc:
      "  هي عبارة عن نظام أساسي اجتماعي يربط الأشخاص الذين يسافرون والذين يرغبون في شحن شيء ما.",
  },
  // 
  goToHomepage:"العودة إلى الصفحة الرئيسية",
  cantFindPage:"لا يمكننا العثور على تلك الصفحة.",
  kg: "كغ",
  afterYourExperience:"بعد تجربتك",
  noTrips: "لا يوجد رحلات",
  noShipment:"لايوجد شحنات",
  seeAll:"عرض الكل",
  pleaseWait: "الرجاء الإنتظار",
  registerMessage: "تسجيل الحساب ، لبدء استخدام خدمات Anapog",
  waitingToAccept: "انتظر الموافقة على المزايدة",
  reportMessage: "نص الإبلاغ",
  reportTitle: "هل أنت متأكد من الإبلاغ عن هذه الشحنة؟",
  products: "منتج",
  deliveryShipment: "شحنة توصيل",
  order_number: "رقم الطلب",
  orderName: "اسم الطلب",
  searsh: "بحث",
  InProgress: "قيد المعالجة",
  OfflineProduct: "اضافة منتج",
  loremAddLink: "أضف رابط منتج",
  rating: "التقييم",
  sendRate: "ارسل التقييم",
  orders: "الطلبات",
  updateProfile: "تعديل الملف الشخصي",
  EL: "ادخل رابط المنتج",
  AddLink: "أضف رابط منتج",
  cantAddfourImage: "لا يمكنك إضافة أكثر من 4 صور",
  name: "الاسم",
  search: "بحث",
  selectChat: "إبدء المحادثة",
  acceptingBids: "قبول المزايدات",
  enterCode: "ادخل الرمز",
  sendCode:"أرسل الرمز",
  codeSenyShipment: "أدخل الرمز الخاص الذي أستلمته من فضلك",
  Readmore: "قراءة المزيد",
  enterYourPrice: "الرجاء إدخال السعر والتاريخ لهذه الشحنة",
  noBidsYet: "لا يوجد مزايدات",
  goToTheCity: "ابحث عن المكان",
  submit: "أرسل",
  noChatsFound: "لا يوجد محادثات",
  showCode:"إضهار رمز التسليم",
  clickToCopy:"نسخ الرمز",
  homepage: {
    shopAnywhere: "تسوق في أي مكان ، سافر في كل مكان",
    whatis: "ماهي",
    headTitle:
      "أنابوغ هي عبارة عن منصة اجتماعية تربط الأشخاص الذين يسافرون ، والذين يرغبون في شحن شيء ما. ",
    secondTitle: "أرسل أي حزمة في أي مكان وفي أي وقت بأمان وسهولة",
    travelers: [
      {
        title: " اختر غرض يمكنك اخذه معك و توصيله واكسب المال أثناء السفر",
        desc: `ابحث عن الاغراض المراد توصيلها بناءً على وجهتك القادمة واكسب المال أثناء السفر`,
      },
      {
        title: " استفسر عن تفاصيل الغرض المراد شرائه او ارساله.",
        desc: "تحدث مع شريكك من خلال التشات للاتفاق على تفاصيل التوصيل (السعر، الوزن، التاريخ، مكان التسليم)",
      },
      {
        title: " اختر الغرض المراد شرائه او توصيله",
        desc: "قم باحضار الغرض المحدد وتسليمه إلى المرسل او المتسوق ",
      },
      {
        title: " قم بتسليم الغرض الى المرسل او المتسوق واستلم الاجور",
        desc: "حدد موعد مع شريكك لتسليم الغرض والحصول على الاجور",
      },
    ],
    senders: [
      {
        title: "ادخل ما تريد ارساله:",
        desc: `يمكنك طلب ارسال اي غرض تمتلكه مسبقا عن طريق مسافري انابوغ المعتمدين.`,
      },
      {
        title: "تابع عروض المسافرين: ",
        desc: "سيكون طلبك متاح لجميع المسافرين الذين سيقدمون عروضهم لتوصيل غرضك.",
      },
      {
        title: "اختر العرض المناسب وادفع التكاليف:",
        desc: "بعد الاطلاع على عروض المسافرين، اختر العرض والمسافر المناسب لك. ثم ادفع التكاليف والتي ستسلم للمسافر عند تسليم الاغراض بنجاح.",
      },
      {
        title: " استلام الغرض وتحرير المبلغ:",
        desc: "في هذه الخطوة يتم تسليم الغرض وتحرير الاجور للمسافر من قبل موقع انابوغ بعد تاكيد الاستلام.",
      },
    ],
    services: [
      {
        title: "مدفوعات آمنة عبر الإنترنت",
        desc: "أموالك آمنة مع انابوغ لاننا نستخدم نظام دفع آمن",
      },
      {
        title: "مجتمع المتسوقين والمسافرين المعتمدين",
        desc: "تقوم انابوغ بالتأكد من هوية كل المسافرين المعتدين من قبلها وكما يتيح لك نظام التقييم الاطلاع على تجارب التوصيل السابقة لكل مسافر",
      },
      {
        title: "ضمان التسليم",
        desc: "ضمان الدفع الأجور بعد إتمام التوصيل",
      },
    ],
    recommendProductsDesc:
      "تصفح واختر اي منتج ترغب به، ليقوم مسافر مسجل في ابانوغ بشرائه و توصيله لك.",
    recommendWebsitesDesc:
      "اختر اي منتج من المواقع المقترحة من انابوغ، ليقوم مسافر مسجل في ابانوغ بشرائه و توصيله لك.",
    cardDeliver:
      "لديك أغراض ومتعلقات شخصية في بلد اخر؟ سيقوم مسافروا أنابوغ بتوصيلها لك.",
    cardBuy:
      "اختر المنتج الذي تريد شرائه من ايا موقع عالمي ليقوم مسافروا انابوغ بشرائه و توصيله لك.",
  },
  toAddTrip:
    "لكي تستطيع توصيل غرض يرجى التسجيل على <a href='#'>Anapog.com</a> كمسافر.",
  npShipments: "لا يوجد لديك ايا شحنات بعد",
  agreementReached: "التوصل إلى اتفاق",
  payment: "دفع",
  pending: "قيد الانتظار",
  completed: "مكتمل",
  progress: {
    pending: "تم تفعيل طلبك، ستتلقى عروض التوصيل من المسافرين على انابوغ. ",
    agreementCompleted:
      "تم الموافقة على عرض يرجى اتمام الخطوات للبدء بتنفيذ الطلب",
    payment:
      "تم حجز تكاليف الطلب من قبل انابوغ، وسيتم تسليم هذا المبلغ للمسافر عند اتمام عملية التوصيل",
    inProgress: "جاري العمل على توصيل الطلب",
    completed: "تم تنفيذ الطلب و تأكيد الاستلام من قبلكم",
  },
  shipment: {
    title: "حدد العنصر (العناصر) المطلوب تسليمها:",
    desc: " بمجرد تحديد العنصر (العناصر) ، يمكنك الاتصال بالمرسل لإنهاء الاتفاقية",
  },
  addTripTitle:
    "بمجرد تحديد العنصر (العناصر) ، يمكنك الاتصال بالمرسل لإنهاء الاتفاقية",
  dialogs: {
    shipmentWait: {
      title: "تم إرسال شحنتك إلى المسافر بنجاح",
      desc: "من فضلك انتظر الرد",
    },
    addShipment: "تمت إضافة شحنتك بنجاح",
    goToMyShipemnts: `انتقل إلى "شحناتي"`,
    areYouSure: "هل أنت متأكد من حذف ؟",
    paypal: {
      step1: "الرجاء إدخال حساب باي بال الخاص بك",
      step2: "أرسل إلى اسم المسافر",
    },
    codeTitle: "أدخل الرمز الذي أرسله المسافر إليك",
    rateTitle: "بعد تجربتك مع هذه الرحلة .. ما هو تقييمك؟",
    verifySoon: "سيتم التحقق من حسابك قريبا",
    areYouSureCancel:"هل أنت متأكد الإلغاء"
  },

  logo: "أنابوغ",
  cancel: "إلغاء",
  ok: "موافق",
  yes: "نعم",
  no: "إلغاء",
  wait: "انتظر",
  edit: "تعديل",
  send: "ارسال",
  forSenders: "للمرسلين",
  forTravlers: "للمسافرين",
  deliverForMe: "اشحن لي",
  buyForMe: "اشتري لي",
  addNewShipment: "أضف شحنة جديدة",
  addShipment: "أضف شحنة",
  addProduct: "أضف منتج",
  addYourTrip: "أضف رحلتك",
  allTrips: "جميع الرحلات",
  recomendedProducts: "المنتجات الموصى بها",
  recomendedWebsites: "المواقع الموصى بها",
  downloadApp:
    "للحصول على أفضل تجربة ، قم بتنزيل تطبيقنا لأجهزة iPhone أو Android",
  serviceTitle: "نحن نفخر بأنفسنا على جودة الخدمة",
  serviceDesc: "إنها طريقة آمنة وسهلة وممتعة لكسب المال من السفر حول العالم.",
  goToHompage: "اذهب إلى الصفحة الرئيسية",
  error404: "عذرًا ... لم يتم العثور على الصفحة !!",
  buyNow: "اشتري الآن",
  productDetails: "تفاصيل المنتج",
  link: "نهاية لهذه الغاية",
  category: "فئة",
  Wieght: "وزن",
  price: "سعر",
  description: "وصف",
  country: "دولة",
  distinationAddress: "عنوان الوجهة",
  deliveryDate: "تاريخ التسليم او الوصول",
  orginAddress: "عنوان الرئيسي",
  contactDesc: "املأ النموذج وسيقوم فريقنا بالرد عليك في غضون 24 ساعة",
  GetInTouch: "ابقى على تواصل",
  yourName: "اسمك",
  yourEmail: "بريدك الالكتروني",
  yourMessage: "رسالتك",
  from: "من",
  to: "إلى",
  details: "تفاصيل",
  moreDetails: "المزيد من التفاصيل",
  tripDetails: "تفاصيل الرحلة",
  date: "تاريخ",
  sendMessage: "ارسل رسالة الى",
  requestShipment: "طلب شحنة",
  ship: "شحنة",
  shiped: "أرسلته",
  youDonthaveShipments: "لا يوجد لديك اي شحنة",
  myProfile: "ملفي",
  myTrips: "رحلاتي",
  myShipments: "شحناتي",
  shipments: "الشحنات",
  myWallet: "محفظتى",
  signOut: "خروج",
  budget: "ميزانية",
  shipmentName: "اسم الشحنة",
  notes: "ملحوظات",
  next: "التالي",
  enterDetails: "أدخل التفاصيل",
  enterAddress: "أدخل العنوان",
  address: "العنوان",
  firstName: "الاسم الاول",
  lastName: "الكنية",
  email: "البريد الإلكتروني",
  phoneNumber: "رقم الهاتف",
  editProfile: "تعديل الملف الشخصي",
  password: "كلمة المرور",
  changePassword: "غير كلمة السر",
  oldPassword: "كلمة المرور القديمة",
  newPassword: "كلمة السر الجديدة",
  confirmNewPassword: "تأكيد كلمة المرور الجديدة",
  save: "حفظ",
  allStatus: "كل الحالات",
  status: "حالة",
  currentBids: "المزايدات الحالية",
  deleteShipment: "حذف الشحنة",
  deliveryStage: "مرحلة التسليم",
  codeSentTraveler:"أدخل الرمز الخاص اللذي استلمته من فضلك",
  enterQrCode: "أدخل رمز QR",
  accept: "قبول",
  pleaseEnterPhone: "يرجى إدخال رقم الهاتف الخاص بك",
  receiverDigits: "ستتلقى 4 رموز رقمية للتحقق من رقمك",
  code: "شفرة",
  passport: "جواز سفر",
  paypal: "باي بال",
  weSentCode: "لقد أرسلنا رمز التحقق إلى رقم هاتفك المحمول",
  uploadPassport: "يرجى تحميل جواز السفر الخاص بك",
  dragFiles: "أو قم بسحب وإسقاط ملف PDF الخاص بك",
  upload: "تحميل",
  areYouTraveler: "هل انت مسافر؟",
  total: "المجموع",
  order: "ترتيب",
  totalIn: "إجمالي في",
  totalOut: "إجمالي خارج",
  totalInOut:"الكل",
  in:"الداخل",
  out:"الخارج",
  allShipments: "جميع الشحنات",
  onlyShip: "شحن فقط",
  buyShip: "الشراء والشحن",
  placeBid: "المزايدة",
  trip: "رحلة قصيرة",
  weight: "وزن",
  ratings: "التقييمات",
  editTrip: "تعديل الرحلة",
  addNewTrip: "إضافة رحلة جديدة",
  fromCountry: "من دولة",
  toCountry: "إلى البلد",
  fromCity: "من المدينة",
  toCity: "إلى المدينة",
  frequency: "تكرار",
  originCountry: "بلد المنشأ",
  originCity: "مدينة المنشأ",
  destinationCountry: "بلد المقصد",
  originAddress: "عنوان المنشأ",
  destinationAddress: "عنوان الوجهة",
  destinationCity: "المدينة الوجهة",
  shipmentDetails: "تفاصيل الشحنة",
  inProgress: "في تَقَدم",
  addNewProduct: "اضافة منتج جديد",
  allProducts: "جميع المنتجات",
  categories: "فئات",
  signIn: "تسجيل الدخول",
  signUp: "انشاء حساب",
  welcomeTo: "اهلا بك في ",
  alreadyhaveAnAccount: "يوجد لديك حساب",
  IAgree: "أوافق",
  addTrip: "اضافة رحلة",
  goToMyTrips: "الذهاب الى رحلاتي",
  goToMyShipments: "الذهاب الى منتجاتي",
  goToMyProducts: "الذهاب الى منتجاتي",
  shipmentSuccessfullyAdded: " تم اضافة منتج بنجاح",
  tripSuccessfullyAdded: "تم اضافة رحلة بنجاح",
  productSuccessfullyAdded: "تم اضافة منتج بنجاح",
  shipmentDate: "تاريخ توصيل المنتج",
  productName: "اسم المنتج",
  linkURL: "لينك الموقع",
  addLink: "اضافة الرابط",
  enterProduct: "ادخل المنتج",
  reviewProduct: "عرض المنتج",
  enterAddressSend: "ادخل العنوان وارسل",
  fillUp: "ادخل المعلومات",
  uploadImage: "تحميل صورة ",
  visitLink: "قم بزيارة الموقع",
  note: "تفاصيل المنتج",
  website: "الموقع",
  url: "رابط المنتج",
  waiting: "انتظار",
  noNotificationYet: "لايوجد اشعارات",
  toAddATrip: "من أجل إضافة رحلة",
  verification: "تأكيد",

  pleaseEnterYourPhoneNumber: " الرجاء ادخال رقم هاتفك",
  receiveDigital: "استقبال رقمي",
  countryCode: "رمز البلد",
  weHaveSentCode: "لقد أرسلنا رمزًا إلى هاتفك ، يرجى التحقق ...",
  pleaseInsertCode: "الرجاء إدخال الرمز الذي أرسلناه إلى ",
  verify: "تأكيد",
  back: "رجوع",
  city: "مدينة",
  goQuicklyToCity: "اذهب بسرعة إلى المدينة",
  browse: "تصفح",
  noNotification: "لايوجد اشعارات",
  notifications: "الاشعارات",
  chats: "المحداثات",
  updateEmail: "تعديل الايميل",

  messages: {
    mustBe6: "يجب أن يتكون الرقم من 6 أرقام",
    loggedSuccessfully: "تم تسجيل الدخول بنجاح",
    selectDate: "الرجاء اختيار وقت",
    selectCategory: "الرجاء اختيار صنف",
  },
  ourServices: "خدماتنا",
};
