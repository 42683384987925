import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
  base: process.env.BASE_URL,
});

router.beforeEach(async (to, from, next) => {
  // disable navbar in auth pages
  // if (to.matched.some((record) => record.name == "auth")) {
  //   store.dispatch("setLayout", "auth");
  //   next();
  // } else {
  //   store.dispatch("setLayout", "");
  //   next();
  // }

  // check if logged
  let isLogin;
  if (localStorage.getItem("token")) {
    isLogin = localStorage.getItem("token").toString();
  }
  // redirect to homepage if logged
  if (to.matched.some((record) => record.meta.auth)) {
    if (isLogin) {
      next();
    } else {
      next({
        path: "/sign-in",
      });
    }
  }
  // check if page require auth
  else {
    if (!isLogin && to.matched.some((record) => record.meta.auth)) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  }
});

export default router;
