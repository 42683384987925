<template>
  <div>
    <div class="py-3 c-btn white px-4 view-field">
      <span class="d-block font-22 primary--text font-500"
        >{{ $t(title) }} : <span class="font-18 lightGreyText--text">{{ value }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.view-field {
  min-height: 50px;
}
</style>
