<template>
  <div class="footer z-4 primary " :class="$route.name == 'Homepage' || $route.name == 'auth' || $route.name=='sign-in' ? '': ''">
    <div class="max-width">
      <v-row>
        <v-col cols="12" md="4">
          <img
            class="footer__logo "
            width="200px"
            :src="require('@/assets/images/global/logo-white.png')"
            alt=""
          />
          <div class="mt-3">
            <span class="font-25 white--text">Anapog.com</span>
            <p class="font-17 font-300 white--text mb-7 max-400 mt-1">
              {{ $t("navbar.footerDesc") }}
            </p>

            <div
              class="mt-4 d-flex align-center"
              v-for="(info, i) in infos"
              :key="i"
            >
              <img width="24px" class="mx-3" :src="info.icon" />
              <span class="font-17 white--text font-300" style="direction: ltr;">{{ info.title }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <span class="d-block font-25 white--text mb-5">{{
            $t("navbar.links")
          }}</span>
          <div
            class="footer__link d-flex align-center mb-3"
            v-for="(link, i) in links"
            :key="i"
          >
            <router-link :to="link.route">
              <span class="circle white d-inline-block mx-3"></span>
              <span class="font-17 font-300 white--text">{{ link.title }}</span>
            </router-link>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <span class="d-block font-25 white--text mb-5">{{
            $t("navbar.subscribe")
          }}</span>
          <!-- email -->
          <v-form v-model="valid">
            <v-text-field
              v-model="message"
              color="white"
              outlined
              :hide-details="true"
              :placeholder="`${$t('navbar.message')}`"
              type="text"
              dark
              @click:append-outer="sendMessage"
            >
            <template #append >
            <v-icon :style=" $vuetify.rtl? 'transform: rotate(180deg)' :'transform: rotate(0deg)'">mdi-send</v-icon>
          </template>
          </v-text-field>
          </v-form>
          <!-- social -->
          <v-row class="mt-10">
            <v-col cols="6" v-for="(social, i) in socials" :key="i">
              <div class="d-flex align-center">
                <img class="mx-2" width="50px" height="50px" :src="social.img" alt="" />
                <div>
                  <span class="font-15 white--text d-block font-300">{{
                    social.title
                  }}</span>
                  <a href="">
                    <span class="font-15 white--text d-block">{{
                      social.type
                    }}</span>
                  </a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: (vm) => ({
    infos: [
      {
        title: "+971 54 995 9160",
        icon: require("@/assets/images/icons/phone-light.png"),
      },
      {
        title: "Info@anapog.com",
        icon: require("@/assets/images/icons/email-light.png"),
      },
    ],
    links: [
      { title: vm.$t("navbar.aboutUs"), route: "/about" },
      { title: vm.$t("navbar.contact"), route: "/contact-us" },
      { title: vm.$t("navbar.privacy"), route: "/privacy" },
      { title: vm.$t("navbar.termsOfUs"), route: "/terms-of-use" },
    ],
    socials: [
      {
        title: vm.$t("navbar.likeUsOn"),
        img: require("@/assets/images/global/facebook.png"),
        type: "Facebook",
      },
      {
        title: vm.$t("navbar.followUsOn"),
        img: require("@/assets/images/global/insta.png"),
        type: "Instagram",
      },
    ],

    message: "",
    valid: false,
  }),
  methods: {
    sendMessage() {
      if (!this.valid) return;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 60px 0px 60px 0px;
  position: relative;
}
</style>
