<template>
  <div class="base-input input-style relative">
    <v-text-field
      class="w-100"
      :class="isBudget ? 'base-budget' : ''"
      :type="type"
      outlined
      :placeholder="placeholder"
      dense
      height="35px"
      max-height="35px"
      :rules="rules"
      :hide-details="hideDetails"
      :value="value"
      @input="$emit('input', $event)"
      :rounded="rounded"
      :prepend-inner-icon="icon"
      full-width
      color="purple"
      :readonly="readonly"
      @keypress="isNumber($event)"
    >
    </v-text-field>
    <span v-if="isBudget" class="budget font-15 primary--text d-inline-block"
      >USD</span
    >
    <span v-if="isWeight" class="budget font-12 primary--text d-inline-block"
      >{{$t('kg')}}</span
    >
    
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    type: {
      type: String,
      default: "text",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    isBudget: {
      type: Boolean,
      default: false,
    },
    isWeight: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },

  },
  methods: {
    isNumber: function (evt) {
      if (this.isBudget || this.isWeight) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
  },
  computed: {
    darkMood() {
      return this.$store.getters["darkMood"];
    },
  },
};
</script>

<style lang="scss">
.input-style {
  .v-input__slot {
    border-radius: 5px;
    fieldset {
      height: 45px !important;
      border: 1px solid #66656533!important;
      // box-shadow: 0px 2px 10px rgb(168 168 168 / 5%);
    }
    .v-input__append-inner {
      margin-top: 10px !important;
    }

  }
  .error--text {
    .v-input__slot {
      fieldset {
        border: 1px solid #e44358 !important;
      }
    }
  }
}
.base-budget input::-webkit-outer-spin-button,
.base-budget input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
.base-budget input[type="number"] {
  -moz-appearance: textfield !important;
}
.budget {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  padding-left: 10px;
  height: 100%;
  line-height: 44px;
  display: flex;
  &::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-55%);
    width: 1px;
    background: white;
    height: 70%;
  }
}
</style>
